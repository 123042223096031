@mixin makeTypography($mediaquery: "") {

  .#{$mediaquery}fs-h-0 {
    font-size: var(--fs-h-0);
  }
  .#{$mediaquery}fs-h-1 {
    font-size: var(--fs-h-1);
  }

  .#{$mediaquery}fs-h-2 {
    font-size: var(--fs-h-2);
  }

  .#{$mediaquery}fs-h-3 {
    font-size: var(--fs-h-3);
  }

  .#{$mediaquery}fs-h-4 {
    font-size: var(--fs-h-4);
  }

  .#{$mediaquery}fs-h-5 {
    font-size: var(--fs-h-5);
  }

  .#{$mediaquery}fs-h-6 {
    font-size: var(--fs-h-6);
  }

  .#{$mediaquery}fs-h-7 {
    font-size: var(--fs-h-7);
  }


  .#{$mediaquery}fs-h-8 {
    font-size: var(--fs-h-8);
  }

  .#{$mediaquery}fs-xl {
    font-size: var(--fs-xl);
  }

  .#{$mediaquery}fs-lg {
    font-size: var(--fs-lg);
  }

  .#{$mediaquery}fs-md {
    font-size: var(--fs-md);
  }

  .#{$mediaquery}fs-sm {
    font-size: var(--fs-sm);
  }

  .#{$mediaquery}fs-xs {
    font-size: var(--fs-xs);
  }
  .#{$mediaquery}fs-xxs {
    font-size: var(--fs-xxs);
  }

  .#{$mediaquery}lh-h-0 {
    line-height: var(--lh-h-0);
  }

  .#{$mediaquery}lh-h-1 {
    line-height: var(--lh-h-1);
  }

  .#{$mediaquery}lh-h-2 {
    line-height: var(--lh-h-2);
  }

  .#{$mediaquery}lh-h-3 {
    line-height: var(--lh-h-3);
  }

  .#{$mediaquery}lh-h-4 {
    line-height: var(--lh-h-4);
  }

  .#{$mediaquery}lh-h-5 {
    line-height: var(--lh-h-5);
  }

  .#{$mediaquery}lh-h-6 {
    line-height: var(--lh-h-6);
  }

  .#{$mediaquery}lh-h-7 {
    line-height: var(--lh-h-7);
  }

  .#{$mediaquery}lh-xl {
    line-height: var(--lh-xl);
  }

  .#{$mediaquery}lh-lg {
    line-height: var(--lh-lg);
  }

  .#{$mediaquery}lh-md {
    line-height: var(--lh-md);
  }

  .#{$mediaquery}lh-sm {
    line-height: var(--lh-sm);
  }

  .#{$mediaquery}lh-xs {
    line-height: var(--lh-xs);
  }

  .#{$mediaquery}lh-1 {
    line-height: 1;
  }

  .#{$mediaquery}underline {
    text-decoration: underline;
  }

  .#{$mediaquery}text-italic {
    font-style: italic;
  }

  .#{$mediaquery}text-left,
  .#{$mediaquery}ql-align-left {
    text-align: left;
  }

  .#{$mediaquery}text-center,
  .#{$mediaquery}ql-align-center {
    text-align: center;
  }

  .#{$mediaquery}text-justify,
  .#{$mediaquery}ql-align-justify {
    text-align: justify;
  }

  .#{$mediaquery}text-right,
  .#{$mediaquery}ql-align-right {
    text-align: right;
  }

  .#{$mediaquery}text-uppercase {
    text-transform: uppercase;
  }

  .#{$mediaquery}text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .#{$mediaquery}text-nowrap {
    white-space: nowrap;
  }

  .#{$mediaquery}list {
    list-style-position: inside;
  }

  .#{$mediaquery}fw-400 {
    font-weight: var(--fw-400);
  }

  .#{$mediaquery}fw-500 {
    font-weight: var(--fw-500);
  }

  .#{$mediaquery}fw-700 {
    font-weight: var(--fw-700);
  }
}

:root {
  --icon-w: 24px;
}

.flipicon {
  display: block;
  width: var(--icon-w);
  min-width: var(--icon-w);
  max-width: var(--icon-w);
  height: var(--icon-w);
  min-height: var(--icon-w);
  max-height: var(--icon-w);
  font-size: var(--icon-w);

  &--xs {
    --icon-w: 32px;
  }

  &--sm {
    --icon-w: 40px;
  }

  &--md {
    --icon-w: 48px;
  }

  &--lg {
    --icon-w: 56px;
  }

  &--xl {
    --icon-w: 64px;
  }

  &--xxl {
    --icon-w: 80px;
  }

  &--xxxl {
    --icon-w: 180px;
  }

  &--popup {
    --icon-w: 160px;
    &-sm {
      --icon-w: 80px;
    }
  }
}

a {
  text-decoration: none;
}

@include makeTypography();

@media all and (max-width: $mediaSM) {
  @include makeTypography(sm-);

  .sm {
    &-flipicon {
      --icon-w: 24px;

      &--md {
        --icon-w: 32px;
      }

      &--lg {
        --icon-w: 48px;
      }

      &--xl {
        --icon-w: 64px;
      }

      &--xxl {
        --icon-w: 100px;
      }

      &--popup {
        --icon-w: 160px;
      }
    }
  }
}

@media all and (max-width: $mediaXS) {
  @include makeTypography(xs-);

  .xs {
    &-flipicon {
      --icon-w: 24px;

      &--md {
        --icon-w: 32px;
      }

      &--lg {
        --icon-w: 48px;
      }

      &--xl {
        --icon-w: 64px;
      }

      &--xxl {
        --icon-w: 100px;
      }

      &--popup {
        --icon-w: 160px;
      }
    }
  }
}

.font-londrina {
  font-family: $font-londrina;
  font-weight: 400 !important;
}

.font-primary {
  font-family: "Montserrat", sans-serif;
}
