:root {
}

.footer {
  --logo-h: 56px;
  margin-top: auto;
  box-shadow:
    0 -8px 0 var(--primary-c-light),
    0 -16px 0 var(--primary-c-light-2);

  &-in {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-form {
    width: 100%;
    max-width: 328px;

    .input {
      min-width: unset;
    }

    .btn {
      min-width: 120px;
    }
  }

  @media all and (max-width: $mediaSM) {
    --logo-h: 80px;

    &-in {
      flex-direction: column;
      align-items: center;
    }

    &-logo {
      order: 1;
    }

    &-form {
      order: 2;
    }

    &-menu {
      order: 3;
    }
  }

  @media all and (max-width: $mediaXS) {
    &-in {
      align-items: flex-start;
    }
  }
}
