:root {
  --progress-border: var(--primary-c);
  --progress-bg: var(--primary-c-light-2);
}

.progress {
  &-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: 8px;
    border: 1px solid var(--progress-border);
    border-radius: 4px;
    min-width: 124px;

    &--dark {
      --progress-border: var(--primary-c);
      --progress-bg: var(--text-c-2);
    }
  }

  &-in {
    height: 100%;
    transition: width 0.3s;
    will-change: width;
    background-color: var(--progress-bg);
    border-radius: inherit;
  }
}
