.bg-none {
  background-color: transparent;
}

.bg-primary {
  background-color: var(--primary-c);
}

.bg-primary-light {
  background-color: var(--primary-c-light);
}

.bg-primary-light-2 {
  background-color: var(--primary-c-light-2);
}

.bg-primary-light-3 {
  background-color: var(--primary-c-light-3);
}

.bg-primary-light-4 {
  background-color: var(--primary-c-light-4);
}

.bg-primary-dark {
  background-color: var(--primary-c-dark);
}

.bg-topics-1 {
  background-color: var(--bg-violet);
}

.bg-topics-2 {
  background-color: var(--bg-green);
}

.bg-topics-3 {
  background-color: var(--bg-orange);
}

.bg-topics-4 {
  background-color: var(--bg-yellow);
}

.bg-topics-5 {
  background-color: var(--bg-blue);
}

.bg-topics-6 {
  background-color: var(--bg-pink);
}

.bg-white {
  background-color: var(--bg-white);
}

.bg-purple {
  background-color: var(--bg-purple);
}


.bg-yellow {
  background-color: var(--bg-yellow-crayola);

}



.c-primary {
  color: var(--primary-c);
}

.c-primary-light {
  color: var(--primary-c-light);
}

.c-primary-light-2 {
  color: var(--primary-c-light-2);
}

.c-primary-light-3 {
  color: var(--primary-c-light-3);
}

.c-primary-light-4 {
  color: var(--primary-c-light-4);
}

.c-primary-dark {
  color: var(--primary-c-dark);
}

.c-text-white {
  color: var(--text-c-white);
}

.c-text-black {
  color: var(--text-c-black);
}

.c-text-1 {
  color: var(--text-c-1);
}

.c-text-2 {
  color: var(--text-c-2);
}

.c-text-3 {
  color: var(--text-c-3);
}

.c-text-4 {
  color: var(--text-c-4);
}
