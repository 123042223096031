.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-btn {
    $self: &;
    position: relative;
    padding: 0 8px;
    font-size: var(--fs-lg);
    line-height: var(--lh-lg);
    font-weight: var(--fw-700);
    color: var(--text-c-4);
    text-transform: capitalize;

    @media all and (max-width: #{$mediaXS}) {
      font-size: var(--fs-sm);
      line-height: var(--lh-sm);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      width: 1px;
      height: 100%;
      z-index: 1;
      background-color: var(--text-c-4);
    }

    &:hover, &--active {
      color: var(--primary-c);
    }

    &--active {
      pointer-events: none;
    }

    &--active, &--return {
      &:before {
        display: none;
      }
    }
  }
}

.subtopics-last-name{
  &:before{
    display: none;
  }
}