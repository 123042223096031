.form-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-btn {
    position: relative;
    min-width: 80px;
    height: 24px;
    margin: 0 6px;
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;

    &:disabled,
    &[disabled],
    &[disabled="disabled"],
    &--disabled {
      pointer-events: none;

      &:before {
        background-color: var(--primary-c-light-2);
      }

      &:after {
        opacity: 0;
      }
    }

    &:before {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      background-color: var(--primary-c);
      border-radius: 4px;
      transition: all .3s ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -4px;
      right: -4px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      border-radius: 6px;
      height: 12px;
      border: 2px solid var(--primary-c);
      opacity: 0;
      transition: all .3s ease-in-out;
    }

    &:hover {
      &:after {
        opacity: .5;
      }
    }

    &--active {
      &, &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    @media all and (max-width: #{$mediaSM}) {
      min-width: unset;
    }

    @media all and (max-width: #{$mediaXS}) {
      margin: 0 4px;

      &:after {
        height: 10px;
        left: -2px;
        right: -2px;
        border: 1px solid var(--primary-c);
      }
    }
  }
}