@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3w7imr') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3w7imr') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3w7imr##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.flipicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flipicon-check {
  &:before {
    content: $flipicon-check; 
  }
}
.flipicon-checkbox {
  &:before {
    content: $flipicon-checkbox; 
  }
}
.flipicon-checkbox-checked {
  &:before {
    content: $flipicon-checkbox-checked; 
  }
}
.flipicon-chevron-down {
  &:before {
    content: $flipicon-chevron-down; 
  }
}
.flipicon-chevron-up {
  &:before {
    content: $flipicon-chevron-up; 
  }
}
.flipicon-close {
  &:before {
    content: $flipicon-close; 
  }
}
.flipicon-exit {
  &:before {
    content: $flipicon-exit; 
  }
}
.flipicon-facebook {
  &:before {
    content: $flipicon-facebook; 
  }
}
.flipicon-facebook-color .path1 {
  &:before {
    content: $flipicon-facebook-color-path1;  
    color: rgb(24, 119, 242);
  }
}
.flipicon-facebook-color .path2 {
  &:before {
    content: $flipicon-facebook-color-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.flipicon-flag {
  &:before {
    content: $flipicon-flag; 
  }
}
.flipicon-flag-fill {
  &:before {
    content: $flipicon-flag-fill; 
  }
}
.flipicon-game {
  &:before {
    content: $flipicon-game; 
  }
}
.flipicon-google {
  &:before {
    content: $flipicon-google; 
  }
}
.flipicon-google-color .path1 {
  &:before {
    content: $flipicon-google-color-path1;  
    color: rgb(255, 193, 7);
  }
}
.flipicon-google-color .path2 {
  &:before {
    content: $flipicon-google-color-path2;  
    margin-left: -1em;  
    color: rgb(255, 61, 0);
  }
}
.flipicon-google-color .path3 {
  &:before {
    content: $flipicon-google-color-path3;  
    margin-left: -1em;  
    color: rgb(76, 175, 80);
  }
}
.flipicon-google-color .path4 {
  &:before {
    content: $flipicon-google-color-path4;  
    margin-left: -1em;  
    color: rgb(25, 118, 210);
  }
}
.flipicon-image {
  &:before {
    content: $flipicon-image; 
  }
}
.flipicon-instagram {
  &:before {
    content: $flipicon-instagram; 
  }
}
.flipicon-left {
  &:before {
    content: $flipicon-left; 
  }
}
.flipicon-left-circle .path1 {
  &:before {
    content: $flipicon-left-circle-path1;  
    color: rgb(0, 0, 0);
  }
}
.flipicon-left-circle .path2 {
  &:before {
    content: $flipicon-left-circle-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.flipicon-like {
  &:before {
    content: $flipicon-like; 
  }
}
.flipicon-like-fill {
  &:before {
    content: $flipicon-like-fill; 
  }
}
.flipicon-link {
  &:before {
    content: $flipicon-link; 
  }
}
.flipicon-url {
  &:before {
    content: $flipicon-url; 
  }
}
.flipicon-menu {
  &:before {
    content: $flipicon-menu; 
  }
}
.flipicon-paw-1 {
  &:before {
    content: $flipicon-paw-1; 
  }
}
.flipicon-paw-2 {
  &:before {
    content: $flipicon-paw-2; 
  }
}
.flipicon-paw-3 {
  &:before {
    content: $flipicon-paw-3; 
  }
}
.flipicon-photo {
  &:before {
    content: $flipicon-photo; 
  }
}
.flipicon-play {
  &:before {
    content: $flipicon-play; 
  }
}
.flipicon-right {
  &:before {
    content: $flipicon-right; 
  }
}
.flipicon-right-circle .path1 {
  &:before {
    content: $flipicon-right-circle-path1;  
    color: rgb(0, 0, 0);
  }
}
.flipicon-right-circle .path2 {
  &:before {
    content: $flipicon-right-circle-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.flipicon-scrapbook {
  &:before {
    content: $flipicon-scrapbook; 
  }
}
.flipicon-search {
  &:before {
    content: $flipicon-search; 
  }
}
.flipicon-tiktok {
  &:before {
    content: $flipicon-tiktok; 
  }
}
.flipicon-topics {
  &:before {
    content: $flipicon-topics; 
  }
}
.flipicon-x {
  &:before {
    content: $flipicon-x; 
  }
}

