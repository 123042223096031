.game-container {
  max-width: 312px;
  height: auto;
  border-radius: 8px;
  padding: 6px;
  position: relative;
  box-shadow: -2px 4px 0px 0px rgba(0, 0, 0, 0.40);
  background:  linear-gradient(180deg, #7C17E1 0%, #4A0DAA 100%);
  &--sm {
    max-width: 280px;
    width: 100%;
  }

}

.question {
  background: var(--bg-purple);
  box-shadow: -3px 4px 0px 0px rgba(0, 0, 0, 0.40);
  border-radius: 16px;
  height: 100%;
  width: 100%;
  max-width: 310px;
  min-height: 50px;

}
.answer-backLayer {
  background: linear-gradient(180deg, #7C17E1 0%, #4A0DAA 100%);
  padding: 6px;
  box-shadow: -2px 4px 0px 0px rgba(0, 0, 0, 0.40);
  border-radius: 24px;
  overflow: hidden;


}
.answer-frontLayer {
  padding: 6px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}
.answer-item {
  /*width: 152px;
  max-width: 153px;
  border-image: linear-gradient(180deg, rgba(96,215,241,1) 0%, rgba(0,114,171,1) 100%);
  border-width: 4px;
  border-style: solid;

  background: #1C9DC9;
  padding: 10px;*/
  /* width: 152px;
   padding: 10px;
   background: linear-gradient(180deg, #60D7F1 0%, #0072AB 100%), linear-gradient(0deg, #1C9DC9, #1C9DC9);
   border-width: 2px;
   border-image: linear-gradient(180deg, rgba(96,215,241,1) 0%, rgba(0,114,171,1) 100%);
   border-radius: 16px;
   line-height: 18px;
   letter-spacing: 0.04em;
   word-break: break-all;
   background-origin: border-box;
   background-clip: padding-box, border-box;
   display: block;*/

  background: radial-gradient(circle at 100% 100%, #1c9dc9 0, #1c9dc9 12px, transparent 12px) 0% 0%/14px 14px no-repeat,
  radial-gradient(circle at 0 100%, #1c9dc9 0, #1c9dc9 12px, transparent 12px) 100% 0%/14px 14px no-repeat,
  radial-gradient(circle at 100% 0, #1c9dc9 0, #1c9dc9 12px, transparent 12px) 0% 100%/14px 14px no-repeat,
  radial-gradient(circle at 0 0, #1c9dc9 0, #1c9dc9 12px, transparent 12px) 100% 100%/14px 14px no-repeat,
  linear-gradient(#1c9dc9, #1c9dc9) 50% 50%/calc(100% - 4px) calc(100% - 28px) no-repeat,
  linear-gradient(#1c9dc9, #1c9dc9) 50% 50%/calc(100% - 28px) calc(100% - 4px) no-repeat,
  linear-gradient(#60d7f1 0%, #0072ab 100%);
  border-radius: 14px;
  padding: 6px;
  box-sizing: border-box;
  max-width: 153px;
  width: 100%;
  aspect-ratio: 153/56;
  height: 100%;
  line-height: 1;
  letter-spacing: .7px;


}

.matching-flex{
  display: flex;
  gap: 8px;
  .matching-flex-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    .matching-answer {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(#72FFFF, #007AA1);
      border-radius: 14px;
      border: 2px #72FFFF solid;
      width: 140px;
      height: 140px;

      &--sm {
        @media all and (max-width: $mediaSM) {
          width: 110px;
          height: 110px;
        }
        @media all and (max-width: $mediaXS) {
          width: 90px;
          height: 90px;
        }
      }
    }
  }
  .matching-answer > span {
    font-size: 12px;
    text-align: center;
  }
}

.four-photo{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  &--item {
    flex: 0 0 48%;
    max-width: 152px;
    height: auto;
    width: 100%;
    object-fit: contain;
    display: block;
    border-radius: 3px;
    overflow: hidden;
  }
  &--img {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}
.single-photo {

  &--media {

    width: 100%;
    height: auto;
  }

}
.guessBreed {
  position: relative;
  width: 100%;
  height: auto;
  &-media {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &--tiles {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

  }
  .tile--item {
    width: 100%;
    aspect-ratio: 1;
    height: 100%;
    background-color: transparent;
    border-radius: 4px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #6112c4;
    &.hint {
      background-image: url("/assets/game/reveal.jpg");

    }

    &.tile {
      background-image: url("/assets/game/question.jpg");

    }
  }
}

.game-img {
  display: block;
  aspect-ratio: 1;
  width: 100%;
  height: auto;
}

.play-btn {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-video {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

