.dog-card {
  &-wrapper {
    --input-bg: #F5D36A;
    --input-c: #8E47C6;
    --input-label-c: var(--input-c);
    font-family: $font-londrina;
    background-image: url("../../assets/img/dog-card-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1000;
    overflow-y: auto;

    @media all and (min-width: $mediaXS) {
      min-height: 100vh;
    }

    @media all and (max-width: $mediaXS) {
      background-image: url("../../assets/img/dog-card-bg-m.webp");
      background-size: cover;
      background-position: center top;
    }

    .input {
      $input: &;
      font-family: inherit;
      font-weight: 400;
      resize: none;

      &-label {
        top: var(--input-p-v);
        --input-label-fs: var(--input-fs);
        --input-label-lh: var(--input-lh);
      }

      + &-label {
        transform: translate3d(0, 0, 0);
        background: transparent!important;
      }

      &:focus,
      &:not(:placeholder-shown) {
        --input-border: var(--primary-c);

        + .input-label {
          --input-label-fs: var(--input-fs);
          --input-label-lh: var(--input-lh);
          transform: translate3d(0, 0, 0);
        }
      }

      &:not(:placeholder-shown) {
        + .input-label {
          opacity: 0;
        }
      }

      &.select {
        --input-label-c: var(--input-c);
        &:focus,
        &:not(:placeholder-shown) {
          + .input-label {
            --input-label-fs: var(--input-fs);
            --input-label-lh: var(--input-lh);
            transform: translate3d(0, 0, 0);
            --input-label-c: var(--input-c);
            opacity: 1;
          }
        }

        &.select--selected {
          + .input-label {
            opacity: 0;
          }
        }
      }
    }

    .checkbox-wrap {
      color: #F5D36A;

      a {
        color: #39FFE7;
      }
    }
  }

  &-title {
    --fs: 64px;
    --stroke: 12px;
    --ls: 3px;
    position: relative;
    font-size: var(--fs);
    line-height: 1;
    letter-spacing: var(--ls);

    @media all and (max-width: $mediaXS) {
      --fs: 32px;
      --stroke: 6px;
      --ls: 2px;
    }

    &:before,
    &:after {
      content: attr(data-text);
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      font-size: inherit;
    }

    &:before {
      color: #6D3697;
      z-index: 1;
      text-stroke: var(--stroke) #6D3697;
      -webkit-text-stroke: var(--stroke) #6D3697;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      transform: translate3d(0, 0, 0);
      filter: drop-shadow(-4px 6px 0 rgba(0, 0, 0, 0.40));
    }

    &:after {
      color: transparent;
      background: linear-gradient(180deg, #B0BF38 0%, #B0BF38 25.01%, #6AF5EE 50.15%, #40FFFF 75%, #A6A6FF 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 2;
    }
  }

  &-icon {
    width: 80px;

    @media all and (max-width: $mediaSM) {
      width: 48px;
    }

    @media all and (max-width: $mediaXS) {
      width: 32px;
    }
  }

  &-body {
    border-radius: 16px;
    border: 4px solid #7C17E0;
    background: #7151C3;
  }

  &-form {
    &-title {
      letter-spacing: 1px;
      color: #F5D36A;
    }

    &-grid {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      @media all and (min-width: $mediaXS) {
        display: grid;
        grid-template-columns: 264px 1fr 1fr;
        column-gap: 24px;
      }

      @media all and (min-width: $mediaSM) {
        column-gap: 48px;
      }

      @media all and (min-width: $mediaXS) and (max-width: $mediaSM) {
        grid-template-columns: 264px 1fr;
        grid-template-areas:
                "image inputs"
                "image inputs-2"
      }
    }

    @media all and (min-width: $mediaXS) and (max-width: $mediaSM) {
      &-col:last-child {
        grid-area: inputs-2;
      }
    }

    &-photo {
      width: 100%;
      max-width: 264px;
      aspect-ratio: 1;
      color: #E0C2FF;
      background: #B26CF0;
      border-radius: 8px;

      &-wrap {
        @media all and (max-width: $mediaXS) {
          border-bottom: 1px solid #F4B9FF;
        }
      }
    }

    &-btn {
      font-size: var(--fs-xl);
      height: 48px;
      line-height: 1;
      padding: 0 16px;
      border-radius: 8px;
      text-transform: uppercase;
      box-shadow: 0 3px 0 0 #000;
      letter-spacing: 1px;
      font-family: $font-londrina;
      font-weight: 400;

      &--pink {
        color: #8E47C6;
        background: #F4B9FF;
      }

      &--blue {
        color: #FFF;
        background: #1C9DC9;
      }

      &:disabled,
      &[disabled],
      &[disabled="disabled"],
      &.btn--disabled {
        opacity: .5;
      }
    }
  }

  .c-text-3 {
    color: #FF4848;
  }

  &-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.80);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;

    .dog-card-title {
      --fs: 40px;
      --stroke: 6px;
      --ls: 0.4px;
    }

    .dog-card-icon {
      width: 40px;
    }

    @media all and (max-width: $mediaXS) {
      .dog-card-title {
        --fs: 32px;
        --stroke: 4px;
        --ls: 0.4px;
      }

      .dog-card-icon {
        width: 32px;
      }
    }

    &-bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &-in {
      position: relative;
      max-width: 480px;
      border-radius: 8px;
      border: 2px solid #7C17E0;
      background: #7151C3;
      z-index: 2;
    }

    &-crop {
      &-wrap {
        width: 100%;
      }
    }

    .ReactCrop,
    .ReactCrop__child-wrapper {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }
}
