:root {
  --primary-c: #1c9dc9;
  --primary-c-rgb: 28, 157, 201;
  --primary-c-light: #94dff8;
  --primary-c-light-rgb: 148, 223, 248;
  --primary-c-light-2: #b7e9fa;
  --primary-c-light-2-rgb: 183, 233, 250;
  --primary-c-light-3: #d0f3ff;
  --primary-c-light-3-rgb: 208, 243, 255;
  --primary-c-light-4: #eaf7fc;
  --primary-c-light-4-rgb: 234, 247, 252;
  --primary-c-light-5: #e7e9ea;
  --primary-c-light-5-rgb: 231, 233, 234;
  --primary-c-dark: #177fa3;
  --primary-c-dark-rgb: 23, 127, 163;

  --text-c-1: #02455c;
  --text-c-2: #08759a;
  --text-c-3: #eb2424;
  --text-c-4: #323232;
  --text-c-5: #808080;
  --text-c-white: #fff;
  --text-c-black: #000;

  --bg-violet: #E9D0FF;
  --bg-green: #BCE689;
  --bg-orange: #FFCF9F;
  --bg-purple: #6C00E0;
  --bg-yellow: #FFF09F;
  --bg-yellow-crayola: #F5D36A;
  --bg-blue: #AEE4F8;
  --bg-pink: #FFD8F9;
  --bg-white: #FFFF;
}
