$icomoon-font-family: "flipicons" !default;
$icomoon-font-path: "fonts" !default;

$flipicon-check: "\e915";
$flipicon-checkbox: "\e924";
$flipicon-checkbox-checked: "\e925";
$flipicon-chevron-down: "\e923";
$flipicon-chevron-up: "\e909";
$flipicon-close: "\e900";
$flipicon-exit: "\e916";
$flipicon-facebook: "\e901";
$flipicon-facebook-color-path1: "\e917";
$flipicon-facebook-color-path2: "\e918";
$flipicon-flag: "\e902";
$flipicon-flag-fill: "\e922";
$flipicon-game: "\e903";
$flipicon-google: "\e904";
$flipicon-google-color-path1: "\e919";
$flipicon-google-color-path2: "\e91a";
$flipicon-google-color-path3: "\e91b";
$flipicon-google-color-path4: "\e91c";
$flipicon-image: "\e921";
$flipicon-instagram: "\e905";
$flipicon-left: "\e906";
$flipicon-left-circle-path1: "\e907";
$flipicon-left-circle-path2: "\e908";
$flipicon-like: "\e91f";
$flipicon-like-fill: "\e920";
$flipicon-link: "\e926";
$flipicon-url: "\e926";
$flipicon-menu: "\e90a";
$flipicon-paw-1: "\e90b";
$flipicon-paw-2: "\e90c";
$flipicon-paw-3: "\e90d";
$flipicon-photo: "\e91d";
$flipicon-play: "\e91e";
$flipicon-right: "\e90e";
$flipicon-right-circle-path1: "\e90f";
$flipicon-right-circle-path2: "\e910";
$flipicon-scrapbook: "\e911";
$flipicon-search: "\e912";
$flipicon-tiktok: "\e913";
$flipicon-topics: "\e914";
$flipicon-x: "\e927";

