@keyframes selectfocus {
  0%, 100% {
    opacity: 0;
  }

  0%, 99% {
    pointer-events: all;
  }

  100% {
    pointer-events: none;
  }
}

:root {
  --input-border-w: 1px;
  --input-p-v: 14px;
  --input-p-h: 14px;
  --input-p-icon: 28px;
  --input-fs: 20px;
  --input-lh: 28px;
  --input-bg: #fff;
  --input-c: var(--text-c-4);
  --input-border: var(--text-c-5);
  --input-placeholder-c: var(--text-c-5);
  --input-label-p: 4px;
  --input-label-c: var(--text-c-5);
  --input-br: 10px;
}

.select-wrap {
  $self: &;
  --input-label-fs: var(--input-fs);
  --input-label-lh: var(--input-lh);

  &:focus-within {
    --input-border: var(--primary-c);

    .select {
      --input-border: var(--primary-c);
    }

    .select-dropdown-wrap {
      opacity: 1;
      pointer-events: all;
    }

    .select-dropdown-item {
      animation: none;
    }
  }

  &:not(:focus-within) {
    --input-border: var(--text-c-5);

    select {
      --input-border: var(--text-c-5);
    }

    .select-dropdown-item {
      animation: selectfocus .1s linear .1s forwards;
    }
  }

  &:not(#{$self}--mobile) {
    option {
      display: none;
    }
  }

  &--mobile {
    .select-dropdown-wrap {
      display: none !important;
    }
  }
}

.select {
  &-z {
    position: relative;
    z-index: 18;

    &:hover,
    &:focus,
    &:focus-within {
      z-index: 20;
    }
  }

  &-dropdown {
    &-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: calc(var(--input-lh) + (var(--input-p-v) * 2)) 0 0 0;
      border-radius: var(--input-br);
      border: var(--input-border-w) solid var(--primary-c);
      background-color: var(--input-bg);
      opacity: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      pointer-events: none;
      max-height: calc(((var(--input-lh) + (var(--input-p-v))) / 2) + (var(--input-lh) + (var(--input-p-v) * 2)) + (var(--input-p-v) + var(--input-lh)) * 7);
      overflow-y: auto;
    }

    &-item {
      position: relative;
      cursor: pointer;
      font-size: var(--input-fs);
      line-height: var(--input-lh);
      padding: calc(var(--input-p-v) / 2) var(--input-p-h);
      transition: color .3s;
      pointer-events: all;
      z-index: -1;

      &:hover, &--selected {
        color: var(--primary-c);
      }

      &--selected {
        pointer-events: none;
      }
    }
  }
}

.input {
  $self: &;
  display: block;
  width: 100%;
  min-width: 200px;
  color: var(--input-c);
  font-size: var(--input-fs);
  font-weight: var(--fw-500);
  line-height: var(--input-lh);
  padding: calc(var(--input-p-v) - var(--input-border-w)) calc(var(--input-p-h) - var(--input-border-w));
  border: var(--input-border-w) solid var(--input-border);
  background-color: var(--input-bg);
  border-radius: var(--input-br);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.3s;

  &-label {
    display: block;
    max-width: calc(100% - var(--input-p-h) * 2);
    padding: 0 var(--input-label-p);
    font-size: var(--input-label-fs);
    line-height: var(--input-label-lh);
    color: var(--input-label-c);
    transition: all 0.3s;
    will-change: transform, color, font-size;
    font-weight: var(--fw-500);
    background-color: var(--input-bg);
    isolation: isolate;
    z-index: 13;
  }

  + #{$self}-label {
    pointer-events: none;
    position: absolute;
    left: calc(var(--input-p-h) - var(--input-label-p));
    top: 0;
    transform: translate3d(0, var(--input-p-v), 0);
  }

  &::-webkit-input-placeholder {
    color: var(--input-placeholder-c);
  }

  &::-moz-placeholder {
    color: var(--input-placeholder-c);
  }

  &:-ms-input-placeholder {
    color: var(--input-placeholder-c);
  }

  &:-moz-placeholder {
    color: var(--input-placeholder-c);
  }

  &:not(:last-child) {
    --input-placeholder-c: transparent;
  }

  &:hover {
    --input-border: var(--text-c-4);
  }

  &:focus,
  &:not(:placeholder-shown) {
    --input-border: var(--primary-c);

    + #{$self}-label {
      --input-label-fs: 12px;
      --input-label-lh: 14px;
      --input-label-c: var(--text-c-4);
      transform: translate3d(0, -50%, 0);
    }
  }

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &--disabled {
    opacity: .5;
    pointer-events: none !important;

    + #{$self}-label {
      opacity: .5;
    }
  }

  &.select {
    position: relative;
    z-index: 11;
    color: transparent;

    &:focus,
    &:not(:placeholder-shown) {

      + #{$self}-label {
        --input-label-fs: var(--input-fs);
        --input-label-lh: var(--input-lh);
        --input-label-c: var(--text-c-5);
        transform: translate3d(0, var(--input-p-v), 0);
      }
    }

    &.select--selected {
      color: var(--input-c);

      &, &:focus, &:not(:placeholder-shown) {
        + #{$self}-label {
          --input-label-fs: 12px;
          --input-label-lh: 14px;
          --input-label-c: var(--text-c-4);
          transform: translate3d(0, -50%, 0);
        }
      }
    }
  }


  &-error-text {
    display: block;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    height: 14px;
    color: var(--text-c-3);
    margin-top: 4px;
    &-50 {
        height: 50px;
    }
  }

  &-hasicon {
    &--left {
      padding-left: calc(var(--input-p-icon) + var(--input-p-h) * 2);
    }

    &--right {
      padding-right: calc(var(--input-p-icon) + var(--input-p-h) * 2);
    }
  }

  &-wrap {
    position: relative;
    --input-label-fs: var(--input-fs);
    --input-label-lh: var(--input-lh);
    flex-direction: column-reverse;
    display: flex;

    &--lg {
      --input-p-v: 16px;
      --input-p-h: 16px;
      --input-p-icon: 32px;
      --input-fs: 24px;
      --input-lh: 32px;
    }

    &--sm {
      --input-p-v: 8px;
      --input-p-h: 8px;
      --input-p-icon: 24px;
      --input-fs: 16px;
      --input-lh: 24px;
    }

    &--dark {
      --input-border-w: 2px;
      --input-bg: var(--primary-c-light-3);
      --input-c: var(--primary-c-dark);
      --input-border: var(--primary-c);
      --input-placeholder-c: var(--primary-c);
      --input-label-c: var(--primary-c);

      #{$self} {
        &:hover {
          --input-border: var(--primary-c-dark);
        }

        &:focus,
        &:not(:placeholder-shown) {
          --input-border: var(--primary-c);

          + #{$self}-label {
            --input-label-c: var(--primary-c);
          }
        }
      }
    }

    &--light {
      --input-bg: transparent;
      --input-c: var(--text-c-white);
      --input-border: var(--text-c-white);
      --input-placeholder-c: var(--text-c-white);
      --input-label-c: var(--text-c-white);

      #{$self} {
        &:hover {
          --input-border: var(--text-c-white);
        }

        &:focus,
        &:not(:placeholder-shown) {
          --input-border: var(--primary-c-light);

          + #{$self}-label {
            --input-label-c: var(--text-c-white);
          }
        }
      }
    }

    &-btn {
      position: absolute;
      top: 50%;
      z-index: 13;
      transform: translate3d(0, -50%, 0);
      --icon-w: var(--input-p-icon);

      &--left {
        left: var(--input-p-h);
      }

      &--right {
        right: var(--input-p-h);
      }
    }
  }
}

textarea.input {
  min-width: 100%;
  max-width: 100%;
  transition: color .3s ease-in-out, border-color .3s ease-in-out;
}

@media all and (max-width: $mediaSM) {
  .sm {
    &-input-wrap {
      --input-p-v: 14px;
      --input-p-h: 14px;
      --input-p-icon: 28px;
      --input-fs: 20px;
      --input-lh: 28px;

      &--lg {
        --input-p-v: 16px;
        --input-p-h: 16px;
        --input-p-icon: 32px;
        --input-fs: 24px;
        --input-lh: 32px;
      }

      &--sm {
        --input-p-v: 8px;
        --input-p-h: 8px;
        --input-p-icon: 24px;
        --input-fs: 16px;
        --input-lh: 24px;
      }
    }
  }
}

@media all and (max-width: $mediaXS) {
  .xs {
    &-input-wrap {
      &,
      &--lg {
        --input-p-v: 10px;
        --input-p-h: 16px;
        --input-p-icon: 24px;
        --input-fs: 18px;
        --input-lh: 28px;
      }

      &--sm {
        --input-p-v: 8px;
        --input-p-h: 10px;
        --input-p-icon: 24px;
        --input-fs: 14px;
        --input-lh: 24px;
      }
    }
  }
}

.checkbox {
  $self: &;

  &-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-input {
    display: none;

    &[type="checkbox"] {
      + #{$self}-icon-holder {
        &:before {
          content: $flipicon-checkbox;
        }
      }

      &:checked {
        + #{$self}-icon-holder {
          &:before {
            content: $flipicon-checkbox-checked;
          }
        }
      }
    }

  }

  &-text {
    margin-left: 4px;
  }
}

