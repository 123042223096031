.ReactModal {
  &__Body--open,
  &__Html--open {
    overflow: hidden;
  }

  &__Overlay {
    background-color: transparent !important;
    z-index: 100;
    pointer-events: none;
  }
}

.popup {
  --popup-p: 30px;
  --popup-h: 100%;
  --popup-card-p: 24px;
  --popup-card-maxw: 480px;
  --popup-card-minh: 608px;
  --popup-card-br: 16px;
  --popup-card-b: 2px solid transparent;
  --avatar-w: 160px;
  --avatar-p: 3px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--popup-h);
  z-index: 1000;
  overflow: hidden;
  padding: var(--popup-p);
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: all;

  &--sm {
    @media all and (min-width: $mediaXS) {
      --popup-card-maxw: 400px;
      --popup-card-minh: 384px;
      --avatar-p: 2px;
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    text-align: inherit;
    width: 100%;
    min-height: var(--popup-card-minh);
    max-width: var(--popup-card-maxw);
    padding: var(--popup-card-p);
    background-color: var(--text-c-white);
    border: var(--popup-card-b);
    border-radius: var(--popup-card-br);
    margin: auto;
    overflow-y: auto;

    &--primary {
      border-color: var(--primary-c);
    }

    &-title {
      font-size: var(--fs-h-7);
      font-weight: var(--fw-700);
      line-height: var(--lh-h-7);
      color: var(--primary-c);
    }

    &-text {
      margin-top: 16px;
      font-size: var(--fs-md);
      line-height: var(--lh-lg);
      font-weight: var(--fw-500);
      color: var(--text-c-2);
    }

    &-media {
      max-width: 160px;
    }

    a {
      text-decoration: none;
    }
  }
}

@media all and (max-width: $mediaXS) {
  .xs {
    &-popup {
      --popup-p: 24px;
      --popup-card-maxw: 312px;
      --popup-card-minh: 0;
      --popup-card-br: 10px;

      &--full {
        --popup-p: unset;
        --popup-h: calc(100% - var(--header-h));
        --popup-card-maxw: 100%;
        --popup-card-minh: 100%;
        --popup-card-br: unset;
        --popup-card-b: none;
        --popup-card-p: 24px;
        top: var(--header-h);
      }
    }
  }
}
