.daily-reminder {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 24px;
}

.daily-reminder-text{
  max-width: 600px;
}

.daily-reminder-subs-btn {
  width: fit-content;
  margin-bottom: 20px;
}