:root {
  --btn-mw: 200px;
  --btn-h: 56px;
  --btn-p: 14px;
  --btn-br: 10px;
  --btn-fw: 700;
  --btn-fs: 20px;
  --btn-lh: 28px;
}

.btn-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  transition:
    color 0.3s,
    border-color 0.3s,
    background-color 0.3s,
    opacity 0.3s;
  cursor: pointer;

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &--disabled {
    color: var(--text-c-white);
    border-color: transparent !important;
    background-color: transparent !important;
    pointer-events: none;
  }
}

.btn {
  $self: &;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: var(--btn-mw);
  height: var(--btn-h);
  padding: var(--btn-p);
  outline: none;
  color: var(--btn-c);
  background-color: var(--btn-bg);
  border: none;
  border-radius: var(--btn-br);
  font-size: var(--btn-fs);
  font-weight: var(--btn-fw);
  line-height: var(--btn-lh);
  text-transform: uppercase;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    color 0.3s,
    border-color 0.3s,
    background-color 0.3s,
    opacity 0.3s,
    transform 0.3s,
    box-shadow 0.3s;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:not(#{$self}--text) {
    box-shadow: 0 4px 0 0 var(--text-c-black);

    &:active {
      box-shadow: 0 0 0 0 var(--text-c-black);
      transform: translateY(2px);
    }
  }

  &--auto {
    min-width: unset;
  }

  &--full {
    min-width: unset;
    width: 100%;
  }

  &--sm {
    --btn-mw: 140px;
    --btn-h: 40px;
    --btn-p: 8px;
    --btn-br: 8px;
    --btn-fs: 16px;
    --btn-lh: 24px;

    &:not(#{$self}--text) {
      box-shadow: 0 2px 0 0 var(--text-c-black);

      &:active {
        box-shadow: 0 0 0 0 var(--text-c-black);
        transform: translateY(1px);
      }
    }
    &-share{
      --btn-mw: 120px;
      --btn-h: 40px;
      --btn-p: 8px;
      --btn-br: 8px;
      --btn-fs: 16px;
      --btn-lh: 24px;
    }
  }

  &--xl {
    --btn-mw: 240px;
    --btn-h: 90px;
    --btn-p: 16px;
    --btn-br: 14px;
    --btn-fs: 24px;
    --btn-lh: 32px;
  }

  &--lg {
    --btn-mw: 240px;
    --btn-h: 64px;
    --btn-p: 16px;
    --btn-br: 14px;
    --btn-fs: 24px;
    --btn-lh: 32px;
  }

  &--social {
    --btn-mw: 208px;
    --btn-h: 40px;
    --btn-p: 8px;
    --btn-fs: 16px;
    --btn-lh: 24px;
    --btn-fw: 500;
    --btn-bg: var(--text-c-white);
    border: 1px solid var(--primary-c);
    text-transform: capitalize;
    &:not(#{$self}--text) {
      box-shadow: none;
    }
  }

  &--outline {
    --btn-c: var(--text-c-4);
    --btn-bg: var(--text-c-white);
    --btn-fw: 500;
    border: 1px solid var(--primary-c);
    text-transform: none;
    box-shadow: none !important;

    &:hover {
      --btn-c: var(--primary-c);
      --btn-bg: var(--primary-c-light-3);
    }

    &:active {
      --btn-c: var(--text-c-4);
      --btn-bg: var(--text-c-white);
    }
  }

  &--primary {
    --btn-c: var(--text-c-white);
    --btn-bg: var(--primary-c);

    &:hover {
      --btn-bg: var(--primary-c-light);
    }

    &:active {
      --btn-bg: var(--primary-c-dark);
    }
    @media all and (max-width: $mediaXS) {
      &:hover {
        --btn-bg: var(--primary-c);
      }

    }
  }

  &--secondary {
    --btn-c: var(--primary-c);
    --btn-bg: var(--text-c-white);
    border: 2px solid var(--primary-c);

    &:hover {
      --btn-c: var(--primary-c-light);
      --btn-bg: var(--text-c-white);
      border-color: var(--primary-c-light);
    }

    &:active {
      --btn-c: var(--primary-c-dark);
      --btn-bg: var(--text-c-white);
      border-color: var(--primary-c-dark);
    }
  }

  &--dark {
    --text-c-black: #fff;
    --btn-c: var(--text-c-white);
    --btn-bg: #000;

    &:hover {
      --btn-bg: var(--text-c-1);
    }

    &:active {
      --btn-bg: #000;
    }
  }

  &--text {
    --btn-bg: transparent;
    --btn-c: var(--primary-c);
    &-lg {
      width: 80px;
      @media all and (max-width: $mediaXS){
        width: unset;
      }
    }


    &:hover {
      --btn-c: var(--primary-c-light);
      @media all and (max-width: $mediaXS){
        --btn-c: var(--primary-c-dark);
      }
    }

    &:active {
      --btn-c: var(--primary-c-dark);
    }
  }

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &--disabled {
    --btn-c: var(--text-c-white);
    --btn-bg: var(--primary-c-light-5);
    pointer-events: none;

    &#{$self}-text {
      --btn-c: var(--primary-c-light-2);
      --btn-bg: transparent;
    }

    &#{$self}--secondary {
      --btn-c: var(--text-c-white);
      --btn-bg: var(--primary-c-light-5);
      border-color:  var(--btn-bg);

    }
  }
}

@media all and (max-width: $mediaSM) {
  .sm {
    &-btn {
      --btn-mw: 200px;
      --btn-h: 56px;
      --btn-p: 14px;
      --btn-br: 10px;
      --btn-fw: 700;
      --btn-fs: 20px;
      --btn-lh: 28px;

      &--auto {
        min-width: unset;
      }

      &--lg {
        --btn-mw: 240px;
        --btn-h: 64px;
        --btn-p: 16px;
        --btn-br: 14px;
        --btn-fs: 24px;
        --btn-lh: 32px;
      }

      &--sm {
        --btn-mw: 140px;
        --btn-h: 40px;
        --btn-p: 8px;
        --btn-br: 8px;
        --btn-fs: 16px;
        --btn-lh: 24px;
      }
    }
  }
}

@media all and (max-width: $mediaXS) {
  .xs {
    &-btn {
      --btn-mw: 160px;
      --btn-h: 40px;
      --btn-p: 8px;
      --btn-br: 8px;
      --btn-fw: 700;
      --btn-fs: 14px;
      --btn-lh: 20px;

      &--auto {
        min-width: unset;
      }

      &:not(.btn--text) {
        box-shadow: 0 2px 0 0 var(--text-c-black);

        &:active {
          box-shadow: 0 0 0 0 var(--text-c-black);
          transform: translateY(1px);
        }
      }

      &--lg {
        --btn-mw: 240px;
        --btn-h: 48px;
        --btn-p: 10px 16px;
        --btn-br: 10px;
        --btn-fs: 18px;
        --btn-lh: 28px;
      }

      &--sm {
        --btn-mw: 96px;
        --btn-h: 28px;
        --btn-p: 8px;
        --btn-br: 6px;
        --btn-fs: 10px;
        --btn-lh: 12px;

        &:not(.btn--text) {
          box-shadow: 0 1px 0 0 var(--text-c-black);

          &:active {
            box-shadow: 0 0 0 0 var(--text-c-black);
            transform: translateY(0);
          }
        }
      }
    }
  }
}
