html,
body,
button,
input,
select,
textarea {
  font-family: "Montserrat", sans-serif;
}

@import "../../assets/flipicons/variables";
@import "../../assets/flipicons/style";

:root {
  --fs-h-0: 105px;
  --fs-h-1: 80px;
  --fs-h-2: 64px;
  --fs-h-3: 48px;
  --fs-h-4: 40px;
  --fs-h-5: 32px;
  --fs-h-6: 28px;
  --fs-h-7: 24px;
  --fs-h-8: 22px;

  --lh-h-0: 110px;
  --lh-h-1: 88px;
  --lh-h-2: 80px;
  --lh-h-3: 56px;
  --lh-h-4: 48px;
  --lh-h-5: 40px;
  --lh-h-6: 36px;
  --lh-h-7: 32px;

  --fs-xl: 20px;
  --fs-lg: 18px;
  --fs-md: 16px;
  --fs-sm: 14px;
  --fs-xs: 12px;
  --fs-xxs: 10px;

  --lh-xl: 28px;
  --lh-lg: 28px;
  --lh-md: 24px;
  --lh-sm: 20px;
  --lh-xs: 14px;


  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
}


.list {
  &-item {
    list-style-position: outside;
    list-style-type: disc;
    position: relative;
    &--submit {
      list-style-position: inside;
    }
  }


}

.invisible {
  position: absolute;
  inset: 0;
  z-index: -1;
}



