html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: var(--primary-c-light-4);
  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
}

:root {
  --logo-h: 80px;

  @media all and (max-width: $mediaSM) {
    --logo-h: 32px;
  }

  @media all and (max-width: $mediaXS) {
    --logo-h: 28px;
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;

  &.game {
    .game-holder-show {
      height: 100vh;
    }
    .game-holder {
      overflow: hidden;
      width: 100%;
      height: calc(var(--screen-h) - var(--header-h) - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));
      max-height: calc(var(--screen-h) - var(--header-h) - var(--ion-safe-area-top) - var(--ion-safe-area-bottom));


      iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

#root.showMode {
  display: grid;
  grid-template-rows: 1fr 0 0;
  flex-direction: unset;
  justify-content: unset;
  height: 100vh;
}

.wrap {
  flex-grow: 1;
  padding-bottom: 16px;
}


.logo {
  display: block;
  width: auto;
  height: var(--logo-h);
}

.no-scroll {
  overflow: hidden !important;
}

.container {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;

  &--sm {
    max-width: 960px;
  }

  @media all and (max-width: $mediaSM) {
    max-width: 990px;
  }
}

.submit-media {

  &--sm {
    max-width: 198px;
    @media all and (max-width: $mediaSM) {
      max-width: 100px;
    }

    @media all and (max-width: $mediaXS) {
      max-width: 170px;
    }
  }

  &--lg {
    width: 100%;
    aspect-ratio: 1.565;
    background-color: var(--primary-c-light-4);

  }
}

.topics {
  &-main {
    max-width: 880px;
    width: 100%;

    @media all and (max-width: $mediaSM) {
      max-width: 475px;
    }

    @media all and (max-width: $mediaXS) {
      max-width: unset;
    }
  }

  &-sidebar {
    @media all and (min-width: $mediaXS) {
      max-width: 380px;
    }
  }

  &-media {
    max-width: 160px;

    @media all and (max-width: $mediaSM) {
      max-width: 90px;
    }

    @media all and (max-width: $mediaXS) {
      max-width: 128px;
      transform: translateY(-50%);
    }

    &--lg {
      width: 100%;
      max-width: 360px;
      aspect-ratio: 1.48;

      @media all and (max-width: $mediaSM) {
        max-width: 312px;
      }

      @media all and (max-width: $mediaXS) {
        width: unset;
        max-width: unset;
      }
    }


    &--sm {
      @media all and (max-width: $mediaXS) {
        max-width: 96px;
        transform: translateY(-5%);
      }
    }
  }




}




.article-wrap {
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .media {
    &, * {
      display: contents;
    }

    &:nth-of-type(odd) {
      img, .media-element, iframe {
        float: right;
        margin-inline-end: 0;
        margin-inline-start: 20px;
      }
    }
  }

  img, .media-element, iframe {
    display: block;
    max-width: 480px;
    width: 100%;
    float: left;
    margin-inline-end: 20px;
    margin-bottom: 20px;
  }

  img, .media-element {
    height: auto;
    border-radius: 16px;
    margin-right: 28px;
   /* margin-top: 10px;*/

    @media all and (max-width: $mediaXS) {
      border-radius: 8px;
    }
  }

  p {
    font-size: var(--fs-lg);
    line-height: 26px;
    margin-bottom: 12px;

    @media all and (max-width: $mediaXS) {
      font-size: var(--fs-md);
      line-height: 26px;
    }
  }

  hr {
    clear: both;
    margin: 20px 0;
    border-top: 1px solid var(--text-c-5);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    clear: both;
  }

  h3 {
    font-size: var(--fs-h-4);
    line-height: var(--lh-h-4);
    font-weight: var(--fw-700);

    @media all and (max-width: $mediaSM) {
      font-size: var(--fs-xl);
      line-height: var(--lh-xl);
    }
  }

  a {
    color: var(--primary-c-dark);
  }
}

.article-media {
  background-color: #6AF5EE;
  border-radius: 16px;
  overflow: hidden;
  .img {
    aspect-ratio: 3/2;
  }
  &--lg {
    max-width: 880px;
    width: 100%;
    @media all and (max-width: $mediaXS) {
      max-width: unset;
    }
  }
  &--md {
    max-width: 480px;
    @media all and (max-width: $mediaSM) {
      max-width: unset;
    }
  }

  &--sm {
    max-width: 428px;
    min-width: 428px ;

    @media all and (max-width: $mediaSM) {
      max-width: 300px;
      min-width: 300px;
    }
    @media all and (max-width: $mediaXS) {
      max-width: unset;
      min-width: unset;
    }
  }

  &--xl {
    max-width: 960px;
    @media all and (max-width: $mediaSM) {
      max-width: unset;
    }
  }
}

.landing-intro {
  &-container {
    width: 100%;
    max-width: 568px;

  }

  &-col {
    width: 100%;
    max-width: 136px;

    @media all and (max-width: $mediaSM) {
      max-width: 106px;
    }
  }

  &-btn-wrap {
    min-width: 100%;
  }

  &-post {
    width: 100%;
    max-width: 600px;

    &--media {
      aspect-ratio: 660/337;
      width: 100%;
      object-fit: cover;
      @media all and (max-width: $mediaSM) {
        aspect-ratio: 312/168;
      }
    }
  }

  &-slider-bullet {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    color: var(--primary-c-light);

    &:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background: currentColor;
      border-radius: 50%;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      inset: 2px;
      border: 1px solid transparent;
      border-radius: 50%;
      z-index: 1;
    }

    &--active {
      color: var(--text-c-1);

      &:after {
        border-color: currentColor;
        transition: border-color 0.3s ease-in-out;
      }
    }
  }

  &--question-box {
    padding: 12px;
    background: var(--bg-purple);
    border-radius: 16px;
    box-shadow: -3px 4px 0px 0px rgba(0, 0, 0, 0.40);
    @media all and (max-width: $mediaXS) {
      padding: 10px 6px;
    }
  }

  &--highlight {
    position: absolute;
    bottom: 40px;
    left: -18px;
    width: 100%;
    max-width: 266px;
    pointer-events: none;
    z-index: 2;

    @media all and (max-width: $mediaSM) {
      max-width: 190px;
      left: -8px;
    }

    @media all and (max-width: $mediaXS) {
      max-width: 120px;
      left: unset;
      right: 0;
      bottom: 60px;
    }

    &-wrap {
      position: absolute;
      left: 50%;
      height: 100%;
      width: 100%;
      transform: translateX(-50%);
      z-index: 2;
      pointer-events: none;

      @media all and (max-width: $mediaSM) {
        max-width: 480px;
      }
    }
  }

  &-media {
    aspect-ratio: 408/581;
    object-position: center;
  }

  @media all and (max-width: $mediaXS) {
    &-holder {
      width: 100%;
      height: calc(100vh - var(--header-h));
      overflow: hidden;
    }

    &-slider {
      width: 100%;
      flex-wrap: nowrap;
      height: 100%;
      transition: transform 0.3s ease-in-out;
      will-change: transform;

      &-wrap {
        width: 100%;
        overflow: hidden;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }

    &-container,
    &-post {
      max-width: unset;
      flex: 0 0 100%;
      height: 100%;
      max-height: 100%;
      overflow: hidden;
      @media all and (max-width: $mediaSM) {
        max-width: 480px;
        margin: 0 auto;
      }
    }

    &-wrap {
      flex-grow: 1;
      flex-shrink: 1;
    }

    &-media {
      overflow: hidden;
      height: 100%;


      &-wrap {
        overflow: hidden;
      }

      img.img {
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: top center;
      }

      .score-card {
        height: 100%;

        > .relative {
          height: 100%;
        }
      }
    }
  }
}

.mw-800 {
  max-width: 800px;
}

.landing-dogs-slider-btn {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 1;

  &--prev {
    left: 24px;
  }

  &--next {
    right: 24px;
  }
}

.topics-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media all and (max-width: $mediaXS) {
    grid-template-columns: 1fr;
  }
}

.auth-wrapper {
  max-width: 718px;
  width: 100%;
  @media all and (max-width: $mediaXS) {
    max-width: unset;
  }
}

.score {
  &-wrapper {
    max-width: 480px;
    @media all and (max-width: $mediaXS) {
      max-width: unset;
    }
    @media all and (max-width: $mediaSM) {
      max-width: 500px;
    }
  }

  &--media {
    max-width: 288px;
    background: radial-gradient(125.38% 125.38% at 50% 50%, #AEF13F 0%, #8CC63F 26.83%, #006837 76.34%, #003637 100%);

    &-img {
      aspect-ratio: 1 auto;
      border-radius: 9px;
    }

    @media all and (max-width: $mediaXS) {
      max-width: unset;
    }

    &-home {
      max-width: 408px;
      width: 100%;
    }

    @media all and (max-width: $mediaSM) {
      max-width: unset;
    }
  }

  &-card {

    &--media {
      max-width: 180px;
      border-radius: 4px;
    }
  }

  &-stats {
    font-family: 'Londrina Solid', sans-serif;

    &--frame {
      padding: 4px;
      background: #F5D36A;
      box-shadow: -2px 2px 0px 0px rgba(0, 0, 0, 0.25);
      border-radius: 16px;

    }

    &--back {
      padding: 2px;
      background: linear-gradient(180deg, rgba(170, 90, 255, 1) 0%, rgba(77, 0, 160, 1) 100%);
      border-radius: 16px;

    }

    &--front {
      padding: 16px;
      background: var(--bg-purple);
      border-radius: 16px;

    }

  }
}

.dash {
  &:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 16px;
    background: var(--text-c-white);
    left: 50%;
    bottom: 0;
    top: 50%;
    transform: translate(-50%, -50%);

  }
}

.swiper-media {
  aspect-ratio: 1;
  width: 100%;
  height: 100%;
}

.preview-iframe {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}




