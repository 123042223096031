:root {
  --sidebar-w: 392px;
  --sidebar-p: 24px;
  --sidebar-ml: 48px;
  --sidebar-ml-sm: 24px;
  --sidebar-mb: 48px;
  --sidebar-mb-sm: 24px;
  --sidebar-logo-w: 104px;
  --sidebar-menu-gap: 32px;
  --sidebar-menu-fs: var(--fs-h-5);
  --sidebar-menu-lh: var(--lh-h-5);
  --sidebar-submenu-gap: 8px;
  --sidebar-submenu-fs: var(--fs-h-7);
  --sidebar-submenu-lh: var(--lh-h-7);
  --sidebar-footer-gap: 16px;
  --sidebar-logout-fs: var(--fs-h-5);
  --sidebar-logout-lh: var(--lh-h-5);

  @media all and (max-height: 920px) {
    --sidebar-p: 16px;
    --sidebar-mb: 16px;
    --sidebar-mb-sm: 8px;
    --sidebar-logo-w: 80px;
    --sidebar-menu-gap: 12px;
    --sidebar-menu-fs: var(--fs-h-7);
    --sidebar-menu-lh: var(--lh-h-7);
    --sidebar-submenu-gap: 4px;
    --sidebar-submenu-fs: var(--fs-xl);
    --sidebar-submenu-lh: var(--lh-xl);
    --sidebar-footer-gap: 8px;
    --sidebar-logout-fs: var(--fs-h-6);
    --sidebar-logout-lh: var(--lh-h-6);
  }

  @media all and (max-width: $mediaXS) {
    --sidebar-w: 328px;
    --sidebar-logo-w: 72px;
    --sidebar-menu-gap: 8px;
    --sidebar-submenu-fs: var(--fs-sm);
    --sidebar-submenu-lh: var(--lh-sm);
    --sidebar-logout-fs: var(--fs-h-7);
    --sidebar-logout-lh: var(--lh-h-7);
  }
}

.sidebar {
  $self: &;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  isolation: isolate;

  &:not(#{$self}--open) {
    pointer-events: none;

    &:before {
      opacity: 0;
      transition: opacity 0.3s linear 0.3s;
    }

    #{$self}-in {
      transition: transform 0.3s ease-in-out;
      transform: translate3d(100%, 0, 0);
    }
  }

  &:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(#000, 0.8);
    transition: opacity 0.3s;
    will-change: opacity;
  }

  &-in {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--sidebar-w);
    height: 100%;
    padding: var(--sidebar-p);
    border-radius: var(--sidebar-p) 0 0 var(--sidebar-p);
    background-color: var(--primary-c-light-2);
    overflow-y: auto;
    transition: transform 0.3s ease-in-out 0.3s;
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  .sidebar-logo {
    width: var(--sidebar-logo-w);
    border-radius: 16px;
    overflow: hidden;
  }

  .sidebar-quick-courses{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 16px;
  }

  .sidebar-quick-courses > a > li{
    font-size: 20px;
  }

  &-menu {
    row-gap: var(--sidebar-menu-gap);
    grid-row-gap: var(--sidebar-menu-gap);

    &-item {
      font-size: var(--sidebar-menu-fs);
      line-height: var(--sidebar-menu-lh);
    }
  }

  &-submenu {
    row-gap: var(--sidebar-submenu-gap);
    grid-row-gap: var(--sidebar-submenu-gap);

    &-item {
      font-size: var(--sidebar-submenu-fs);
      line-height: var(--sidebar-submenu-lh);
    }
  }

  &-footer {
    row-gap: var(--sidebar-footer-gap);
    grid-row-gap: var(--sidebar-footer-gap);
  }

  &-logout {
    font-size: var(--sidebar-logout-fs);
    line-height: var(--sidebar-logout-lh);
  }

  &-ml {
    margin-left: var(--sidebar-ml);

    &--sm {
      margin-left: var(--sidebar-ml-sm);
    }

  }

  &-mb {
    margin-bottom: var(--sidebar-mb);

    &--sm {
      margin-bottom: var(--sidebar-mb-sm);
    }
  }

  @media all and (max-width: $mediaXS) {
    &-in {
      width: var(--sidebar-w);
      max-width: calc(100% - 32px);
    }
  }
}
