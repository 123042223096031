:root {
  --avatar-w: 80px;
  --avatar-p: 3px;

  @media all and (max-width: $mediaXS) {
    --avatar-w: 56px;
    --avatar-p: 2px;
  }
}

.avatar-media {
  position: relative;
  width: var(--avatar-w);
  height: var(--avatar-w);
  padding: var(--avatar-p);
  overflow: hidden;
  background: var(--primary-c);
  aspect-ratio: 1;
  @media all and (max-width: $mediaSM) {
    padding: 5px;
  }

  &:not(.no-image):before {
    content: "";
    display: block;
    position: absolute;
    inset: 7.5%;
    border-radius: inherit;
    border: 2px solid var(--primary-c-light);
    z-index: 1;
  }

  .user-name-abbr {
    height: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-c-white);
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    @media all and (max-width: $mediaSM) {
      font-size: 17px;
    }
  }

  &--lg {
    --avatar-w: 108px;
  }
}
