$mediaSM: 1279px;
$mediaXS: 767px;
@import "reset";

$font-londrina: "Londrina Solid", sans-serif;

html,
body {
  margin: 0;
}

@import "components";

@import "components/color-classes";
@import "components/typography-extend";
@import "components/layout-extend";
