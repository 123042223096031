.article-wrap > ul {
  margin-left: 20px;
}

.article-wrap > ol {
  margin-left: 20px;
}

.article-wrap > ol > li:nth-child(n+10) {
  margin-left: 10px;
}

.article-wrap > ol > li:nth-child(n+100) {
  margin-left: 20px;
}