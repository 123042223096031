.landing {
  background: url("../../assets/img/landing-bg.jpg") no-repeat bottom center, linear-gradient(270deg, #EAF7FC 0%, #EAF7FC 16.9%, #E5F6FC 32.48%, #E0F5FC 45.82%, #DBF3FC 54.96%, #D5F2FD 65.1%, #CFF0FD 74.94%, #C7EEFD 82.77%, #BCEBFD 100%);;
  background-size: contain;
  aspect-ratio: 1920/835 auto;
  width: 100%;
 /* padding: 80px 40px 40px 40px;*/
  padding: 30px 20px 45px 20px;
  @media all and (max-width: $mediaXS) {
    background: var(--primary-c-light-4);

  }
  @media all and (max-width: $mediaSM) {
   padding: 30px 20px 20px 20px;
  }
  &-wrapper {
    @media all and (max-width: $mediaXS) {
      height: calc(100svh - var(--header-h));
    }
  }
  &-game-preview {
    max-width: 100%;
    aspect-ratio: 340/600;
    height: 100%;
    &-holder {
      max-width: 100%;
    }
  }
  &--media {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    &:before{
      content: "";
      background: url("../../assets/img/landing-back-phone-new.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: -210px;
      top: 26px;
      aspect-ratio: 428/628;
      height: auto;
      width: 428px;
      @media all and (max-width: $mediaSM) {
        width: 350px;
        aspect-ratio: 315/460;
        left: -93px;
      }
      @media all and (max-width: $mediaXS) {
        display: none;
      }


    }


   &-img {
     background: url("../../assets/img/landing-phone.png");
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     aspect-ratio: 398/678;
     width: 100%;
     display: flex;
     max-width: 398px;
     z-index: 9;
     position: relative;

     @media all and (max-width: $mediaSM) {
       max-width: 325px;
     }

     @media all and (max-width: $mediaXS) {
       width: auto;
       max-width: unset;
       /*height: 100%;*/
       height: calc(100svh - 200px);
     }

   }
  }
  &--title {
    width: 700px;
    @media all and (max-width: $mediaSM) {
      max-width: 405px;
    }

  }
  &--play-btn {
    max-width: 464px;
    @media all and (max-width: $mediaSM) {
      max-width: 364px;
    }
  }
  &-game-preview{
   padding: 0 10px;
    width: 100%;
  }
}
