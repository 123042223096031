:root {
  --header-h: 144px;
  --screen-h: 0px;
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);

  @media all and (max-width: $mediaSM) {
    --header-h: 64px;
  }
}

.header {
  --avatar-w: 56px;
  --avatar-p: 2px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-h);
  min-height: var(--header-h);
  background-color: #fff;

  &-in {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  @media all and (max-width: $mediaSM) {
    --avatar-w: 32px;
    height: 32px;
    .progress-wrap {
      min-width: 96px;
    }
  }

  @media all and (max-width: $mediaXS) {


    .progress-wrap {
      min-width: 46px;
      height: 6px;
    }

    .xs-fs-xxs {
      font-size: 8px;
    }
  }
  .header-container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    @media all and (max-width: $mediaXS) {
      max-width: 280px;
    }
    @media all and (min-width: $mediaXS) and (max-width: $mediaSM) {
      max-width: 990px;
    }
  }

}


.scrapbook-badge {
  content: "";
  position: absolute;
  top: -3px;
  left: 45px;
  background-color: #FF4848;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media all and (max-width: $mediaXS){
    width: 14px;
    height: 14px;
    left: 22px;
  }
  @media all and (max-width: $mediaSM){
    width: 14px;
    height: 14px;
    left: 22px;
  }

}
